import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {InactiveIntegrationDialogComponent} from './inactive-integration-dialog/inactive-integration-dialog.component';
import {ViewChild} from '@angular/core';
import {Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {StoreService} from '../../../../core/src/lib/store/store.service';
import {SquareSeller, User} from '@libs/api-models';
import {Router} from '@angular/router';
import { APISeller, APIUser } from "../../../../core/src/lib/api/api.types";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ngx-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, AfterViewInit{
  charts = false;
  sellerActive = true;
  isSuperadmin = false;
  accountAdmin?: User;

  seller?: APISeller;
  filteredOptions$: Observable<APIUser[]> = of([]);
  checked = true;
  options: APIUser[] = [];
  @ViewChild('autoInput') input?: ElementRef<HTMLInputElement>;

  constructor(
    private dialogService: NbDialogService,
    public store: StoreService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.store.seller$.subscribe(seller => {
      this.seller = seller
    })

    this.store.accountManagers$.subscribe(accountManagers => {
      if (accountManagers) {
        this.options = accountManagers;
      }
    })

    this.store.user$.subscribe(user => {
      if (user) {
        this.isSuperadmin = user.role.permissionLevel === 3;
        if (user.role.permissionLevel === 1) {
          if (!user.seller) {
            this.router.navigate(['auth', 'oauth2']);
          } else {
            this.store.setSeller(user.seller as SquareSeller)
          }
        } else {
          if (!this.store.seller) {
            this.router.navigate(["pages", "manage-sellers"]);
          }
        }
      }
    })
  }

  ngAfterViewInit() {
    this.store.oloIntegration$.pipe(filter((o) => !!o)).subscribe(oloIntegration => {
      if (oloIntegration) {
        this.sellerActive = this.store.user?.role.permissionLevel === 1 ? oloIntegration.active : true;
        if (!this.sellerActive) {
          this.dialogService.open(InactiveIntegrationDialogComponent, {
            context: {
              title: "Inactive Integration"
            },

            closeOnBackdropClick: false
          });
        }
      }
    })
  }


  private filter(value: string): APIUser[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.first_name.concat(optionValue.last_name).toLowerCase().includes(filterValue));
  }

  onSelectionChange(event: APIUser) {
    if (this.seller) {
      if (this.input) {
        this.input.nativeElement.value = [event.first_name, event.last_name].join(" ");
      }
      this.store.updateSeller(this.seller.id, this.seller.name, event.id, this.seller.autoCloseOrdersAfterClose).subscribe(() => {
        if (this.input) {
          this.input.nativeElement.value = "";
        }
      });
    }
  }

  getFilteredOptions(value: string): Observable<APIUser[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    if (this.input) {
      this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
    }
  }

  export(sellerID: number) {
    this.store.exportData(sellerID)
  }
}
