import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { concatMap, Observable } from "rxjs";
import {
  AuthorizeSellerRequest, AuthorizeSellerResponse,
  CreateAccountRequest,
  CreateAccountResponse,
  CreateSellerRequest,
  CreateSellerResponse,
  FailedOrdersResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  GetAccountManagersResponse,
  GetActivityByResourceResponse,
  GetActivityByUserResponse,
  GetIntegrationResponse,
  GetOrderDataResponse,
  GetRolesResponse,
  GetUserResponse,
  IntegrationProvider,
  LoginRequest,
  LoginResponse,
  OloIntegration,
  OrdersPlacedResponse,
  RequestIntegrationShutoffResponse,
  SellerByIDResponse,
  SellerIntegrationsResponse,
  SellerLocationsResponse, SellerLocationUpdateLocation,
  SellersByAdminResponse,
  SuccessRateResponse,
  TotalSalesResponse, UpdateIntegrationRequest, UpdateIntegrationResponse, UpdateSellerLocationsRequest,
  UpdateSellerRequest,
  UpdateSellerResponse,
  UpdateUserRequest,
  UpdateUserResponse
} from "@libs/api-models";
import {HttpParams} from '@angular/common/http';
import { APIOloIntegration } from "./api.types";
import { NbTokenService } from "@nebular/auth";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpService, private token: NbTokenService,) {}

  // AUTH

  createAccount(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    roleID: number
  ): Observable<CreateAccountResponse> {
    const resource = '/auth/create-account';
    const body: CreateAccountRequest = {
      email,
      first_name: firstName,
      last_name: lastName,
      password: password,
      role: roleID,
    };

    return this.http.post<CreateAccountResponse, CreateAccountRequest>(resource, body);
  }

  authorizeSeller(
    authCode: string,
  ): Observable<AuthorizeSellerResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = '/auth/authorize-seller';
      const body: AuthorizeSellerRequest = {
        accessToken: authCode,
      };
      return this.http.post<AuthorizeSellerResponse, AuthorizeSellerRequest>(resource, body, token.getValue());
    }))
  }

  login(email: string, password: string): Observable<LoginResponse> {
    const resource = '/auth/login';
    const body: LoginRequest = {
      email,
      password,
    };
    return this.http.post<LoginResponse, LoginRequest>(resource, body);
  }

  forgotPassword(email: string): Observable<ForgotPasswordResponse> {
    const resource = '/auth/forgot-password';
    const body: ForgotPasswordRequest = {
      email,
    }
    return this.http.post<ForgotPasswordResponse, ForgotPasswordRequest>(resource, body);
  }

  getUserInfo(): Observable<GetUserResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = '/auth/user';
      return this.http.get<GetUserResponse>(resource, token.getValue());
    }));

  }

  updateUserInfo(id: number, email: string, firstName: string, lastName: string, roleID: number, active: boolean, shouldDelete: boolean): Observable<UpdateUserResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = '/auth/user';
      const body: UpdateUserRequest = {
        id,
        email,
        first_name: firstName,
        last_name: lastName,
        role_id: roleID,
        active: shouldDelete ? false : active,
        deleted: shouldDelete
      }
      return this.http.put<UpdateUserResponse, UpdateUserRequest>(resource, body, token.getValue());
    }))
  }

  getUserByID(id: number): Observable<GetUserResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/auth/user/${id}`;
      return this.http.get<GetUserResponse>(resource, token.getValue());
    }))
  }

  getRoles(): Observable<GetRolesResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = '/auth/roles';
      return this.http.get<GetRolesResponse>(resource, token.getValue());
    }))
  }

  getAccountManagers(): Observable<GetAccountManagersResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = '/auth/account-managers';
      return this.http.get<GetAccountManagersResponse>(resource, token.getValue());
    }))
  }

  // DATA

  retrieveOrdersPlaced(sellerID: number, fromDate: Date, locationID?: string): Observable<OrdersPlacedResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/data/${sellerID}/orders-placed`;
      const queryParams = new HttpParams()
        .set('fromDate', fromDate.toISOString())
        .set('location', locationID ? locationID : '');
      return this.http.get<OrdersPlacedResponse>(resource, token.getValue(), queryParams);
    }));
  }

  retrieveSuccessRate(sellerID: number, fromDate: Date, locationID?: string): Observable<SuccessRateResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/data/${sellerID}/success-rate`;
      const queryParams = new HttpParams()
        .set('fromDate', fromDate.toISOString())
        .set('location', locationID ? locationID : '');
      return this.http.get<SuccessRateResponse>(resource, token.getValue(), queryParams);
    }))
  }

  retrieveFailedOrders(sellerID: number, fromDate: Date, locationID?: string): Observable<FailedOrdersResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/data/${sellerID}/failed-orders`;
      const queryParams = new HttpParams()
      .set('fromDate', fromDate.toISOString())
      .set('location', locationID ? locationID : '');
      return this.http.get<FailedOrdersResponse>(resource, token.getValue(), queryParams);
    }))
  }

  retrieveTotalSales(sellerID: number, fromDate: Date, locationID?: string): Observable<TotalSalesResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/data/${sellerID}/total-sales`;
      const queryParams = new HttpParams()
        .set('fromDate', fromDate.toISOString())
        .set('location', locationID ? locationID : '');
      return this.http.get<TotalSalesResponse>(resource, token.getValue(), queryParams);
    }))
  }

  retrieveOrderByID(orderID: number): Observable<GetOrderDataResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/data/orders/${orderID}`;
      return this.http.get<GetOrderDataResponse>(resource, token.getValue());
    }));
  }

  retrieveActivityByResource(resourceType: string, resourceID: number): Observable<GetActivityByResourceResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/data/activity/byResource/${resourceType}/${resourceID}`;
      return this.http.get<GetActivityByResourceResponse>(resource, token.getValue());
    }));
  }

  retrieveActivityByUser(userID: number): Observable<GetActivityByUserResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/data/activity/byUser/${userID}`;
      return this.http.get<GetActivityByUserResponse>(resource, token.getValue());
    }));
  }

  exportSellerData(sellerID: number): Observable<Blob> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/data/${sellerID}/export`;
      return this.http.getFile(resource, token.getValue());
    }));
  }

  // SELLERS

  retrieveSellersByAdmin(): Observable<SellersByAdminResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = '/sellers';
      return this.http.get<SellersByAdminResponse>(resource, token.getValue());
    }));
  }

  updateSeller(sellerID: number, name?: string, adminID?: number, autoCloseOrders: boolean = false): Observable<UpdateSellerResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/sellers/${sellerID}`;
      const body: UpdateSellerRequest = {
        name,
        adminID,
        autoCloseOrders
      }
      return this.http.put<UpdateSellerResponse, UpdateSellerRequest>(resource, body, token.getValue());
    }));
  }

  retrieveSellerLocations(sellerID: number): Observable<SellerLocationsResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/sellers/${sellerID}/locations`;
      return this.http.get<SellerLocationsResponse>(resource, token.getValue());
    }));
  }

  updateSellerLocations(sellerID: number, locations: SellerLocationUpdateLocation[]): Observable<SellerLocationsResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/sellers/${sellerID}/locations`;
      const body: UpdateSellerLocationsRequest = {
        locations,
      }
      return this.http.put<SellerLocationsResponse, UpdateSellerLocationsRequest>(resource, body, token.getValue());
    }));
  }

  updateSellerLocation(sellerID: number, locationID: number, extRef: string): Observable<SellerLocationsResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/sellers/${sellerID}/locations/${locationID}`;
      const body: SellerLocationUpdateLocation = {
        id: locationID,
        extRef,
      }
      return this.http.put<SellerLocationsResponse, SellerLocationUpdateLocation>(resource, body, token.getValue());
    }));
  }

  retrieveSellerByID(sellerID: number): Observable<SellerByIDResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/sellers/${sellerID}`;
      return this.http.get<SellerByIDResponse>(resource, token.getValue());
    }));
  }

  retrieveSellerIntegrations(sellerID: number): Observable<SellerIntegrationsResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/sellers/${sellerID}/integrations`;
      return this.http.get<SellerIntegrationsResponse>(resource, token.getValue());
    }));
  }

  // INTEGRATIONS

  retrieveIntegration(provider: IntegrationProvider, sellerID: number): Observable<GetIntegrationResponse<OloIntegration>> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/integrations/${provider}/${sellerID}`;
      return this.http.get<GetIntegrationResponse<OloIntegration>>(resource, token.getValue());
    }));
  }

  requestIntegrationShutoff(provider: 'olo', sellerID: number): Observable<RequestIntegrationShutoffResponse> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/integrations/${provider}/${sellerID}/request-shutoff`;
      return this.http.post<RequestIntegrationShutoffResponse, Record<string, never>>(resource, {}, token.getValue());
    }));
  }

  updateIntegration(provider: 'olo', sellerID: number, integration: APIOloIntegration): Observable<UpdateIntegrationResponse<OloIntegration>> {
    return this.token.get().pipe(concatMap((token) => {
      const resource = `/integrations/${provider}/${sellerID}`;
      const body: UpdateIntegrationRequest<OloIntegration> = {
        integration,
      }
      return this.http.put<UpdateIntegrationResponse<OloIntegration>, UpdateIntegrationRequest<OloIntegration>>(resource, body, token.getValue());
    }));
  }
}
