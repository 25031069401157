import {Injectable} from '@angular/core';
import { BehaviorSubject, map, Observable, of, switchMap, tap } from "rxjs";
import {ApiService} from '../api/api.service';
import {NbAuthService} from '@nebular/auth';
import * as moment from 'moment';
import { APIActivity, APILocation, APIOloIntegration, APIOrder, APISeller, APIUser } from "../api/api.types";

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  // STATIC
  private _user?: APIUser
  private _accountManagers: APIUser[] = []

  private _seller?: APISeller
  private _sellerLocations: APILocation[] = [];
  private _availableSellers: APISeller[] = []

  private _oloIntegration?: APIOloIntegration;

  private _ordersPlacedToday?: number
  private _ordersPlacedLast30?: number
  private _successRateToday?: number
  private _successRateLast30?: number
  private _failedOrdersToday?: number
  private _failedOrdersLast30?: number
  private _totalSalesToday?: number
  private _totalSalesLast30?: number
  private _allOrdersToday: APIOrder[] = []
  private _allOrdersLast30: APIOrder[] = []

  // SUBJECTS
  private userSubject = new BehaviorSubject<APIUser | undefined>(undefined);
  private accountManagersSubject = new BehaviorSubject<APIUser[]>([]);

  private sellerSubject = new BehaviorSubject<APISeller | undefined>(undefined);
  private sellerLocationsSubject = new BehaviorSubject<APILocation[]>([]);
  private availableSellersSubject = new BehaviorSubject<APISeller[]>([]);

  private oloIntegrationSubject = new BehaviorSubject<APIOloIntegration | undefined>(undefined);

  private ordersPlacedTodaySubject = new BehaviorSubject<number | undefined>(undefined);
  private ordersPlacedLast30Subject = new BehaviorSubject<number | undefined>(undefined);
  private successRateTodaySubject = new BehaviorSubject<number | undefined>(undefined);
  private successRateLast30Subject = new BehaviorSubject<number | undefined>(undefined);
  private failedOrdersTodaySubject = new BehaviorSubject<number | undefined>(undefined);
  private failedOrdersLast30Subject = new BehaviorSubject<number | undefined>(undefined);
  private totalSalesTodaySubject = new BehaviorSubject<number | undefined>(undefined);
  private totalSalesLast30Subject = new BehaviorSubject<number | undefined>(undefined);
  private allOrdersTodaySubject = new BehaviorSubject<APIOrder[] | undefined>(undefined);
  private allOrdersLast30Subject = new BehaviorSubject<APIOrder[] | undefined>(undefined);

  // OBSERVABLES
  public readonly user$ = this.userSubject.asObservable();
  public readonly accountManagers$ = this.accountManagersSubject.asObservable();

  public readonly seller$ = this.sellerSubject.asObservable();
  public readonly sellerLocations$ = this.sellerLocationsSubject.asObservable();
  public readonly availableSellers$ = this.availableSellersSubject.asObservable();

  public readonly oloIntegration$ = this.oloIntegrationSubject.asObservable();

  public readonly ordersPlacedToday$ = this.ordersPlacedTodaySubject.asObservable();
  public readonly ordersPlacedLast30$ = this.ordersPlacedLast30Subject.asObservable();
  public readonly successRateToday$ = this.successRateTodaySubject.asObservable();
  public readonly successRateLast30$ = this.successRateLast30Subject.asObservable();
  public readonly failedOrdersToday$ = this.failedOrdersTodaySubject.asObservable();
  public readonly failedOrdersLast30$ = this.failedOrdersLast30Subject.asObservable();
  public readonly totalSalesToday$ = this.totalSalesTodaySubject.asObservable();
  public readonly totalSalesLast30$ = this.totalSalesLast30Subject.asObservable();
  public readonly allOrdersToday$ = this.allOrdersTodaySubject.asObservable();
  public readonly allOrdersLast30$ = this.allOrdersLast30Subject.asObservable();


  // GETTERS
  get user(): APIUser | undefined {
    return this._user;
  }

  get accountManagers(): APIUser[] {
    return this._accountManagers;
  }

  get seller(): APISeller | undefined {
    return this._seller;
  }

  get availableSellers(): APISeller[] {
    return this._availableSellers;
  }

  get oloIntegration(): APIOloIntegration | undefined {
    return this._oloIntegration;
  }

  get ordersPlacedToday(): number | undefined {
    return this._ordersPlacedToday;
  }

  get ordersPlacedLast30(): number | undefined {
    return this._ordersPlacedLast30;
  }

  get successRateToday(): number | undefined {
    return this._successRateToday;
  }

  get successRateLast30(): number | undefined {
    return this._successRateLast30;
  }

  get failedOrdersToday(): number | undefined {
    return this._failedOrdersToday;
  }

  get failedOrdersLast30(): number | undefined {
    return this._failedOrdersLast30;
  }

  get totalSalesToday(): number | undefined {
    return this._totalSalesToday;
  }

  get totalSalesLast30(): number | undefined {
    return this._totalSalesLast30;
  }

  get allOrdersToday(): APIOrder[] {
    return this._allOrdersToday;
  }

  get allOrdersLast30(): APIOrder[] {
    return this._allOrdersLast30;
  }

  constructor(
    private api: ApiService,
    private auth: NbAuthService
  ) {
    this.auth.onTokenChange().subscribe((token) => {
      if (token.isValid()) {
        this.api.getUserInfo().subscribe(user => {
          this._setUser(user)
          this.setSellerData(user);
        })
      } else {
        this.auth.logout('email')
        this._clearUser()
      }
    })
  }

  private setSellerData(user: APIUser) {
    if (user.role.permissionLevel >= 2) {
      this.api.retrieveSellersByAdmin().subscribe(sellers => {
        this._setAvailableSellers(sellers.sellers);
      });
      if (user.role.permissionLevel === 3) {
        this.api.getAccountManagers().subscribe(accountManagers => {
          this._setAccountManagers(accountManagers.users);
        });
      }
    } else {
      if (user.seller) {
        this.api.retrieveSellerByID(user.seller.id).subscribe(seller => {
          this.setSeller(seller);
        });
      }
    }
  }

// AUTH
  public logout(): void {
    this._clearUser()
  }

  public authorizeSeller(authCode: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.api.authorizeSeller(authCode)
  }

  public updateUser(userID: number, firstName: string, lastName: string, email: string, roleID: number, active: boolean, shouldDelete: boolean): Observable<void> {
    return this.api.updateUserInfo(userID, email, firstName, lastName, roleID, active, shouldDelete).pipe(
      map((user) => {
        if (this.user?.id === user.id) {
         return this._setUser(user)
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.setSellerData(this.user!)
      })
    );
  }

  public createUser(firstName: string, lastName: string, email: string, roleID: number): Observable<void> {
    return this.api.createAccount(email, 'Chepritest123!', firstName, lastName, roleID).pipe(
      switchMap(() => {
        return this.resetPassword(email).pipe(map(() => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return this.setSellerData(this._user!)

        }))
      })
    );
  }

  public resetPassword(email: string): Observable<void> {
    return this.api.forgotPassword(email).pipe(map(() => {return}))
  }

  public getUser(userID: number): Observable<APIUser> {
    return this.api.getUserByID(userID)
  }

  // SELLER

  public setSeller(seller: APISeller): void {
    this._setSeller(seller)
    this._setOloIntegration(seller.id).subscribe()
    this._clearDataTotals();
    this._setDataTotals(seller.id)
    this._setSellerLocations(seller.id).subscribe()
  }

  public updateSeller(sellerID: number, name?: string, adminID?: number, autoCloseOrders?: boolean): Observable<void> {
    return this.api.updateSeller(sellerID, name, adminID, autoCloseOrders).pipe(
      map((seller) => {
        return this.setSeller(seller)
      })
    );
  }

  public updateSellerLocations(locations: APILocation[]): Observable<void> {
    return this.api.updateSellerLocations(this.seller!.id, locations).pipe(
      switchMap(() => {
        return this._setSellerLocations(this.seller!.id)
      })
    );
  }

  public updateSellerLocation(locationID: number, extRef: string): Observable<void> {
    return this.api.updateSellerLocation(this.seller!.id, locationID, extRef).pipe(
      switchMap(() => {
        return this._setSellerLocations(this.seller!.id)
      })
    );
  }

  // INTEGRATIONS

  public updateOloIntegration(oloIntegration: APIOloIntegration, sellerID: number): Observable<void> {
    // return of()
    return this.api.updateIntegration('olo', sellerID, oloIntegration).pipe(
      switchMap(() => {
        if (this._user) {
          this.setSellerData(this._user)
        }
        return this._setOloIntegration(sellerID);
        // return of()
      })
    );
  }

  public requestIntegrationShutoff(integrationProvider: 'olo', sellerID: number): Observable<void> {
    return this.api.requestIntegrationShutoff(integrationProvider, sellerID).pipe(
      switchMap(() => {
        switch (integrationProvider) {
          case 'olo':
            return this._setOloIntegration(sellerID);
            default:
              return of()
        }
      })
    );
  }

  // DATA

  public exportData(sellerID: number): void {
    this.api.exportSellerData(sellerID).subscribe((blob) => {
      const newBlob = new Blob([blob], { type: 'text/csv' });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.download = `seller-${sellerID}-data.csv`;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })
  }

  getActivityData(resourceType: string, resourceID: number): Observable<APIActivity[]> {
    return this.api.retrieveActivityByResource(resourceType, resourceID).pipe(map((response) => {
      return response.activity
    }))
  }

  // SETTERS


  private _setUser(user: APIUser) {
    this._user = user;
    this.userSubject.next(this._user);
  }

  private _clearUser() {
    this._user = undefined;
    this.userSubject.next(undefined);
  }

  private _setAccountManagers(users: APIUser[]) {
    this._accountManagers = users
    this.accountManagersSubject.next(this._accountManagers)
  }

  private _setSeller(seller: APISeller) {
    this._seller = seller;
    this.sellerSubject.next(this._seller);
  }

  private _clearSeller() {
    this._seller = undefined;
    this.sellerSubject.next(undefined);
  }

  private _setAvailableSellers(sellers: APISeller[]) {
    this._availableSellers = sellers;
    this.availableSellersSubject.next(this._availableSellers);
  }

  private _clearAvailableSellers() {
    this._availableSellers = [];
    this.availableSellersSubject.next([]);
  }

  private _setSellerLocations(sellerID: number): Observable<void> {
    return this.api.retrieveSellerLocations(sellerID).pipe(map(locations => {
      this._sellerLocations = locations.locations
      this.sellerLocationsSubject.next(this._sellerLocations)
      return
    }))
  }

  private _setOloIntegration(sellerID: number): Observable<void> {
    return this.api.retrieveSellerIntegrations(sellerID).pipe(map(integration => {
      this._oloIntegration = integration.oloIntegration ?? undefined;
      this.oloIntegrationSubject.next(this._oloIntegration)
      return
    }))
  }

  private _setOrdersPlacedToday(sellerID: number, locationID?: string): Observable<void> {
    const today = moment().startOf('day').toDate()
    return this.api.retrieveOrdersPlaced(sellerID, today, locationID).pipe(map(orders => {
      this._ordersPlacedToday = orders.orders.length
      this.ordersPlacedTodaySubject.next(this._ordersPlacedToday)
      return
    }))
  }

  private _setOrdersPlacedLast30(sellerID: number, locationID?: string): Observable<void> {
    const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day').toDate()
    return this.api.retrieveOrdersPlaced(sellerID, thirtyDaysAgo, locationID).pipe(map(orders => {
      this._ordersPlacedLast30 = orders.orders.length
      this.ordersPlacedLast30Subject.next(this._ordersPlacedLast30)
      return
    }))
  }

  private _setSuccessRateToday(sellerID: number, locationID?: string): Observable<void> {
    const today = moment().startOf('day').toDate()
    return this.api.retrieveSuccessRate(sellerID, today, locationID).pipe(map(successRate => {
      this._successRateToday = successRate.successRate
      this.successRateTodaySubject.next(this._successRateToday)
      return
    }))
  }

  private _setSuccessRateLast30(sellerID: number, locationID?: string): Observable<void> {
    const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day').toDate()
    return this.api.retrieveSuccessRate(sellerID, thirtyDaysAgo, locationID).pipe(map(successRate => {
      this._successRateLast30 = successRate.successRate
      this.successRateLast30Subject.next(this._successRateLast30)
      return
    }))
  }

  private _setFailedOrdersToday(sellerID: number, locationID?: string): Observable<void> {
    const today = moment().startOf('day').toDate()
    return this.api.retrieveFailedOrders(sellerID, today, locationID).pipe(map(orders => {
      this._failedOrdersToday = orders.orders.length
      this.failedOrdersTodaySubject.next(this._failedOrdersToday)
      return
    }))
  }

  private _setFailedOrdersLast30(sellerID: number, locationID?: string): Observable<void> {
    const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day').toDate()
    return this.api.retrieveFailedOrders(sellerID, thirtyDaysAgo, locationID).pipe(map(orders => {
      this._failedOrdersLast30 = orders.orders.length
      this.failedOrdersLast30Subject.next(this._failedOrdersLast30)
      return
    }))
  }

  private _setTotalSalesToday(sellerID: number, locationID?: string): Observable<void> {
    const today = moment().startOf('day').toDate()
    return this.api.retrieveTotalSales(sellerID, today, locationID).pipe(map(sales => {
      this._totalSalesToday = sales.totalCents / 100
      this.totalSalesTodaySubject.next(this._totalSalesToday)
      return
    }))
  }

  private _setTotalSalesLast30(sellerID: number, locationID?: string): Observable<void> {
    const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day').toDate()
    return this.api.retrieveTotalSales(sellerID, thirtyDaysAgo, locationID).pipe(map(sales => {
      this._totalSalesLast30 = sales.totalCents / 100
      this.totalSalesLast30Subject.next(this._totalSalesLast30)
      return
    }))
  }

  private _setAllOrdersToday(sellerID: number, locationID?: string): Observable<void> {
    const today = moment().startOf('day').toDate()
    return this.api.retrieveOrdersPlaced(sellerID, today, locationID).pipe(map(orders => {
      this._allOrdersToday = orders.orders
      this.allOrdersTodaySubject.next(this._allOrdersToday)
      return
    }))
  }

  private _setAllOrdersLast30(sellerID: number, locationID?: string): Observable<void> {
    const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day').toDate()
    return this.api.retrieveOrdersPlaced(sellerID, thirtyDaysAgo, locationID).pipe(map(orders => {
      this._allOrdersLast30 = orders.orders
      this.allOrdersLast30Subject.next(this._allOrdersLast30)
      return
    }))
  }


  private _setDataTotals(sellerID: number, locationID?: string) {
    this._setOrdersPlacedToday(sellerID, locationID).subscribe()
    this._setOrdersPlacedLast30(sellerID, locationID).subscribe()
    this._setSuccessRateToday(sellerID, locationID).subscribe()
    this._setSuccessRateLast30(sellerID, locationID).subscribe()
    this._setFailedOrdersToday(sellerID, locationID).subscribe()
    this._setFailedOrdersLast30(sellerID, locationID).subscribe()
    this._setTotalSalesToday(sellerID, locationID).subscribe()
    this._setTotalSalesLast30(sellerID, locationID).subscribe()
    this._setAllOrdersToday(sellerID, locationID).subscribe()
    this._setAllOrdersLast30(sellerID, locationID).subscribe()
  }

  private _clearDataTotals() {
    this._ordersPlacedToday = undefined;
    this._ordersPlacedLast30 = undefined;
    this._successRateToday = undefined;
    this._successRateLast30 = undefined;
    this._failedOrdersToday = undefined;
    this._failedOrdersLast30 = undefined;
    this._totalSalesToday = undefined;
    this._totalSalesLast30 = undefined;
    this.ordersPlacedTodaySubject.next(undefined)
    this.ordersPlacedLast30Subject.next(undefined)
    this.successRateTodaySubject.next(undefined)
    this.successRateLast30Subject.next(undefined)
    this.failedOrdersTodaySubject.next(undefined)
    this.failedOrdersLast30Subject.next(undefined)
    this.totalSalesTodaySubject.next(undefined)
    this.totalSalesLast30Subject.next(undefined)
  }
}
